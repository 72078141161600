























































import { Vue, Component, Prop } from "vue-property-decorator";
import { ShippingFeeRegionDialogProperties } from "./ShippingFeeRegionDialogView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CountryService from "@/services/CountryService";

@Component({
    data: () => ({
        filter: {
            code: "",
            name: ""
        },
        pageNo: 1,
        pageCount: 1,
        recordCount: 20,
        recordCounts: [10, 20, 30, 40, 50],
        countries: [],
        loading: false
    })
})
export default class ShippingFeeRegionCountryView extends Vue {
    @Prop() private properties: ShippingFeeRegionDialogProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private countryService = new CountryService();

    public get headers() {
        return [
            {
                text: this.$t("text.code"),
                value: "code",
                filter: v => {
                    if (!this.$data.filter.code) return true;
                    return v.toLowerCase().includes(this.$data.filter.code.toLowerCase());
                }
            },
            {
                text: this.$t("text.name"),
                value: "name",
                filter: v => {
                    if (!this.$data.filter.name) return true;
                    return v.toLowerCase().includes(this.$data.filter.name.toLowerCase());
                }
            }
        ];
    }

    public get footerProps() {
        return {
            "items-per-page-options": this.$data.recordCounts
        };
    }

    public created() {
        this.load();
    }

    public async load() {
        this.$data.loading = true;

        try {
            const r = await this.countryService.get();
            const countries = r.data.countries;
            this.$data.countries = countries.sort((lhs, rhs) => lhs.code.localeCompare(rhs.code));
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.loading = false;
        }
    }

    public selectionChanged() {
        var countries = this.properties.selectedCountries;
        var states = this.properties.selectedStates;
        var cities = this.properties.selectedCities;

        states = states.filter(x => {
            return countries.filter(y => y.id === x.countryId).length > 0;
        });
        cities = cities.filter(x => {
            return states.filter(y => y.id === x.stateId).length > 0;
        });
        this.properties.selectedStates = states;
        this.properties.selectedCities = cities;
    }
}
